// i18next-extract-mark-ns-start click-to-pay

import {AnchorLink} from 'components/AnchorLink';
//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {links} from 'global.config';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import click_to_pay_monei from 'images/click_to_pay_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {BlogLink} from 'components/links/Blog';
import {IntegrationGuide} from 'components/payment-methods/IntegrationGuide';
import {InternalPageLink} from 'components/links/Pages';
import {StyledP} from 'components/StyledSx';
import {FaqsSection} from 'components/FaqsSection';

const ClickToPay: React.FC = () => {
  const {t} = useI18next();

  const faqs = [
    {
      header: t('How do I activate Click to Pay?'),
      text: t(
        'As soon as your account has been approved, Click to Pay will be enabled by default.'
      ),
      content: (
        <Trans>
          As soon as your account has been approved, Click to Pay will be enabled by default.
        </Trans>
      )
    },
    {
      header: t('What are the transaction fees for Click to Pay?'),
      text: t(
        'The actual payment method used depends on the credit or debit cards the consumer adds to their Click to Pay account. There are no extra costs to accept Click to Pay through MONEI. Learn more about our fees.'
      ),
      content: (
        <Trans>
          The actual payment method used depends on the credit or debit cards the consumer adds to
          their Click to Pay account. There are no extra costs to accept Click to Pay through MONEI.{' '}
          <AnchorLink href="https://support.monei.com/hc/en-us/articles/360017954318-MONEI-fees">
            Learn more about our fees.
          </AnchorLink>
        </Trans>
      )
    },
    {
      header: t('How does Click to Pay work for consumers?'),
      text: t(
        'When your customer chooses to pay by credit card, they will have the option of saving their payment information for future purchases using Click to Pay. All they’ll have to do is check the Click to Pay box next to the Click to Pay icon, fill in their billing information, and then complete the payment. Then Click to Pay will be enabled for future purchases.'
      ),
      content: (
        <Trans>
          When your customer chooses to{' '}
          <InternalPageLink slug="payment-methods/credit-cards">
            pay by credit card
          </InternalPageLink>
          , they will have the option of saving their payment information for future purchases using
          Click to Pay. All they’ll have to do is check the Click to Pay box next to the Click to
          Pay icon, fill in their billing information, and then complete the payment. Then Click to
          Pay will be enabled for future purchases.
        </Trans>
      )
    },
    {
      header: t('Where is Click to Pay available?'),
      text: t(
        'Click to Pay is available in over half a million online stores in the United States, and is becoming more popular around the world. Be one of the first online stores to offer this easy, secure, and smart payment option in Spain.'
      ),
      content: (
        <Trans>
          Click to Pay is available in over half a million online stores in the United States, and
          is becoming more popular around the world. Be one of the first online stores to offer this
          easy, secure, and smart payment option in Spain.{' '}
        </Trans>
      )
    },
    {
      header: t('What credit card brands work with Click to Pay?'),
      text: t(
        'Currently, Click to Pay powered by MONEI supports Visa and Mastercard credit cards.'
      ),
      content: (
        <Trans>
          Currently, Click to Pay powered by MONEI supports Visa and Mastercard credit cards.
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        title="Click to Pay"
        description={t(
          'Give customers a frictionless and secure payment experience and boost your conversion rate by adding Click to Pay to your online store. Get started here >>'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '550px', md: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Add Click to Pay to your online store</Trans>
              </SectionHeader>
              <Trans parent="p">
                Enhance the customer experience with Click to Pay’s fast and secure, one-click
                payment solution. Be one of the first online businesses to offer this soon-to-be
                standard payment method that’s supported by major credit card networks including
                Visa, Mastercard, and Discover.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
              <StyledP
                sx={{color: '#74858E', marginTop: '16px', fontSize: '14px', fontStyle: 'italic'}}>
                <Trans>
                  *Click to Pay is not currently available to merchants in{' '}
                  <InternalPageLink slug="andorra-payment-gateway">Andorra</InternalPageLink>
                </Trans>
              </StyledP>
            </MainTitleContent>
            <MainTitleImg
              src={click_to_pay_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Click to Pay: how it works</Trans>
              </SectionHeader>
              <Trans parent="p">
                Click to Pay is a reliable and safe payment feature approved by{' '}
                <AnchorLink href="https://www.emvco.com/" target="_blank">
                  EMVCo
                </AnchorLink>
                , the global technical entity that enables secure and seamless card payments to
                benefit businesses, consumers, and financial institutions.
              </Trans>
              <Trans parent="p">
                Click to Pay users only have to add their credit, debit, or prepaid card details to
                the app once. Then they can skip the lengthy guest checkout process and pay in one
                click using the Click to Pay button.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Boost conversions and customer satisfaction</Trans>
              </SectionHeader>
              <Trans parent="p">
                Create a digital-first and{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience
                for your customers and reduce abandoned shopping carts with Click to Pay. Customers
                won’t have to enter their card details and personal information each time they check
                out, but they’ll still be able to use their{' '}
                <BlogLink slug="mastercard-click-to-pay">Mastercard</BlogLink>,{' '}
                <BlogLink slug="click-to-pay-with-visa">Visa</BlogLink> or Discover credit card.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
          </Section>
          <Section>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={700}
              height={700}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept more payment methods with MONEI</Trans>
              </SectionHeader>
              <Trans parent="p">
                Shoppers today are looking for an abundance of payment options. That’s why we
                regularly add new{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                to our platform. You’ll increase brand loyalty by giving your customers the option
                to choose their preferred form of payment.
              </Trans>
              <Trans parent="p">Let MONEI power your e-commerce checkout so you can accept:</Trans>
              <List>
                <Trans parent="li">Click to Pay transactions</Trans>
                <Trans parent="li">
                  All{' '}
                  <InternalPageLink slug="payment-methods/credit-cards">
                    major credit cards
                  </InternalPageLink>{' '}
                </Trans>
                <Trans parent="li">
                  Digital wallets such as{' '}
                  <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                  and{' '}
                  <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>
                </Trans>
                <Trans parent="li">
                  Local payment methods including{' '}
                  <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <IntegrationGuide title={<Trans>Get started with Click to Pay today</Trans>}>
        <Trans>
          Open your MONEI account{' '}
          <AnchorLink href={links.signUp} target="_blank">
            now
          </AnchorLink>{' '}
          to enjoy the benefits of Click to Pay. Integrate the{' '}
          <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
            Hosted Payment Page
          </AnchorLink>{' '}
          with all major e-commerce platforms or your custom-built website.
        </Trans>
      </IntegrationGuide>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Click to pay FAQs</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default ClickToPay;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "click-to-pay"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
